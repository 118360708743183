import 'preline';
import './bootstrap';
import _ from 'lodash';
import './hs-apexcharts-helpers.js'
import { VinContent } from './vin-content.js';
import './mileage-graph.js'
import './sentry.js'
import './visible-menu.js'

const initPreline = () => {
  if (window.HSScrollspy) {
    HSScrollspy.autoInit();
  }

  if (window.HSTooltip) {
    HSTooltip.autoInit();
  }

  if (window.HSCollapse) {
    HSCollapse.autoInit();
  }
  if (window.HSTabs) {
    HSTabs.autoInit();
  }
};

VinContent.onLoaded(() => {
  // Reinitialize Preline when VIN content loads
  initPreline();

  // Handle initial hash
  const hash = window.location.hash;
  if (hash) {
    const targetElement = document.querySelector(hash);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
});