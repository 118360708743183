// Function to scroll active menu item into view
function scrollActiveMenuItemIntoView() {
  const menuContainer = document.querySelector('[data-hs-scrollspy="#content-scroll"]');
  const activeItem = menuContainer?.querySelector('.active');

  // console.log('scroll event triggered', {
  //   menuContainer: !!menuContainer,
  //   activeItem: !!activeItem
  // });

  if (menuContainer && activeItem) {
    // Get the container's scroll position and dimensions
    const containerRect = menuContainer.getBoundingClientRect();
    const activeItemRect = activeItem.getBoundingClientRect();

    // console.log('positions', {
    //   containerLeft: containerRect.left,
    //   containerRight: containerRect.right,
    //   activeItemLeft: activeItemRect.left,
    //   activeItemRight: activeItemRect.right
    // });

    // Check if the active item is outside the visible area
    const isOutOfView =
      activeItemRect.left < containerRect.left ||
      activeItemRect.right > containerRect.right;

    // console.log('isOutOfView', isOutOfView);

    if (isOutOfView) {
      // Calculate the scroll position to center the active item
      const scrollLeft =
        activeItem.offsetLeft -
        (menuContainer.clientWidth / 2) +
        (activeItem.clientWidth / 2);

      // console.log('scrolling to', scrollLeft);

      // Smooth scroll to the active item
      menuContainer.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  }
}

// Listen to scroll on the main scrollable container
const scrollableParent = document.querySelector('#scrollspy-scrollable-parent');
if (scrollableParent) {
  scrollableParent.addEventListener('scroll', scrollActiveMenuItemIntoView);
}

// Also trigger when Preline's scrollspy updates the active state
document.addEventListener('preline.hs.scrollspy.change', () => {
  // console.log('scrollspy change detected');
  scrollActiveMenuItemIntoView();
});

// Initial check when page loads
window.addEventListener('load', () => {
  // console.log('page loaded');
  scrollActiveMenuItemIntoView();
});

// Additional check after a short delay to ensure everything is loaded
setTimeout(scrollActiveMenuItemIntoView, 500);