import ApexCharts from 'apexcharts';
import { VinContent } from './vin-content.js';

VinContent.onLoaded(() => {
  const marker = document.getElementById('mileage-chart-data');

  if (marker) {
    const chartData = JSON.parse(marker.dataset.chart);

    const positiveColor = '#2563eb';
    const negativeColor = '#FF0000';

    let increasingMileage = [];
    let decreasingMileage = [];

    chartData.mileage.forEach((value, index, array) => {
      if (index === 0) {
        // First point is always increasing
        increasingMileage.push(value);
        decreasingMileage.push(null);
      } else if (value >= array[index - 1]) {
        // For increasing, if the previous point was decreasing, add it to increasingMileage
        if (decreasingMileage[decreasingMileage.length - 1] !== null) {
          increasingMileage[increasingMileage.length - 1] = array[index - 1]; // Fix the previous value in increasingMileage
        }
        increasingMileage.push(value); // Add the current increasing value
        decreasingMileage.push(null);  // Null for decreasingMileage
      } else {
        // For decreasing, if the previous point was increasing, add it to decreasingMileage
        if (increasingMileage[increasingMileage.length - 1] !== null) {
          decreasingMileage[decreasingMileage.length - 1] = array[index - 1]; // Fix the previous value in decreasingMileage
        }
        decreasingMileage.push(value);  // Add the current decreasing value
        increasingMileage.push(null);   // Null for increasingMileage
      }
    });

    const numberFormat = (number) => {
      return number != null ?
        number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') :
        null;
    }

    const daysBetweenDates = (date1, date2) => {
      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('.').map(Number);
        return new Date(year, month - 1, day);
      };

      const d1 = parseDate(date1);
      const d2 = parseDate(date2);

      const diffTime = Math.abs(d2 - d1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    const timestamps = chartData.dates.map(date => {
      const [day, month, year] = date.split('. ').map(Number);
      return new Date(year, month - 1, day).getTime();
    });

    const naturalAxisConfig = {
      type: 'datetime',
      categories: timestamps,
      labels: {
        formatter: function (value, timestamp) {
          const date = new Date(timestamp);
          return (date.getMonth() + 1) + ' / ' + date.getFullYear();
        },
        rotateAlways: true,
        offsetX: -1,
      },
      tickAmount: 12,
      min: timestamps[0],
      max: timestamps[timestamps.length - 1]
    };

    var options = {
      chart: {
        animations: {
          enabled: false,
        },
        height: 400,
        type: 'line',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
      },
      series: [
        {
          name: 'Increasing Mileage',
          data: increasingMileage,
          color: positiveColor
        },
        {
          name: 'Decreasing Mileage',
          data: decreasingMileage,
          color: negativeColor
        }
      ],
      markers: {
        size: 5,
        hover: {
          size: 9
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return `${numberFormat(value)} km`;
          },
        },
      },
      xaxis: naturalAxisConfig,
      stroke: {
        curve: 'smooth',
        width: 4
      },
      grid: {
        strokeDashArray: 2
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: true,
        shared: true,
        custom: function ({ dataPointIndex }) {
          const { mileage, dates } = chartData;
          let value = '';

          const trendUpIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-0.5 inline-block text-green-500 size-5"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 5l3 3l-3 3" /><path d="M3 18h2.397a5 5 0 0 0 4.096 -2.133l4.014 -5.734a5 5 0 0 1 4.096 -2.133h3.397" /></svg>`;
          const trendDownIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="-mt-1 mr-0.5 inline-block text-red-500 size-5"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 6h2.397a5 5 0 0 1 4.096 2.133l4.014 5.734a5 5 0 0 0 4.096 2.133h3.397" /><path d="M18 19l3 -3l-3 -3" /></svg>`;

          if (typeof mileage[dataPointIndex] !== 'undefined' && typeof mileage[dataPointIndex - 1] !== 'undefined') {
            if (mileage[dataPointIndex] === mileage[dataPointIndex - 1]) {
              value = 'Žádná změna';
            } else {
              value = `<div class="italic text-center -mt-1 mb-2">${numberFormat(mileage[dataPointIndex])} km</div>`;
            }

            if (mileage[dataPointIndex] < mileage[dataPointIndex - 1]) {
              const daysBetween = daysBetweenDates(dates[dataPointIndex - 1], dates[dataPointIndex]);
              value += `${trendDownIcon} Pokles <span class="font-semibold">${numberFormat(mileage[dataPointIndex - 1] - mileage[dataPointIndex])}</span> km za <span class="font-semibold">${numberFormat(daysBetween)} dní</span>`;
            } else {
              const daysBetween = daysBetweenDates(dates[dataPointIndex - 1], dates[dataPointIndex]);
              const km = mileage[dataPointIndex] - mileage[dataPointIndex - 1];

              if (daysBetween == 0) {
                value += `${trendUpIcon} Nárůst <span class="font-semibold">${numberFormat(km)} km</span>`;
              }

              if (daysBetween > 0) {
                value += `${trendUpIcon} Nárůst <span class="font-semibold">${numberFormat(km)} km</span> za <span class="font-semibold">${numberFormat(daysBetween)} dní</span>`;

                value += `
              <div class="flex flex-col border-t mt-4 pt-5">
                <div class="-m-1.5 overflow-x-auto">
                  <div class="p-1.5 pb-0 min-w-full inline-block align-middle">
                    <div class="overflow-hidden">
                      <table class="min-w-full">
                        <thead>
                          <tr>
                            <th scope="col" class="pr-6 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Průměr</th>
                            <th scope="col" class="pl-6 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">Vzdálenost</th>
                          </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
              `;
              }

              if (daysBetween > 0) {
                value += `
              <tr>
                <td class="pr-6 py-2 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">Denně</td>
                <td class="pl-6 py-2 whitespace-nowrap text-end text-sm">
                  ${Number((km / daysBetween).toFixed(1))} km
                </td>
              </tr>              
              `;
              }
              if (daysBetween > 7) {
                value += `
              <tr>
                <td class="pr-6 py-2 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">Týdně</td>
                <td class="pl-6 py-2 whitespace-nowrap text-end text-sm">
                  ${numberFormat(Number((km / (daysBetween / 7)).toFixed(0)))} km
                </td>
              </tr>              
              `;
              }
              if (daysBetween > 365) {
                value += `
              <tr>
                <td class="pr-6 py-2 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">Ročně</td>
                <td class="pl-6 py-2 whitespace-nowrap text-end text-sm">
                  ${numberFormat(Number((km / (daysBetween / 365)).toFixed(0)))} km
                </td>
              </tr>              
              `;
              }
              if (daysBetween > 0) {
                value += `
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              `;
              }
            }
          } else {
            value = `<div class="italic text-center">${numberFormat(mileage[dataPointIndex])} km</div>`;
          }

          return '<div class="p-4 bg-white text-sm text-gray-800">' + value + '</div>';
        }
      },
      dataLabels: {
        // offsetX: -15,
        offsetY: -8,
        enabled: true,
        enabledOnSeries: true,
        style: {
          colors: [
            function ({ seriesIndex, dataPointIndex, w }) {
              if (w.config.series[seriesIndex].data[dataPointIndex] < w.config.series[seriesIndex].data[dataPointIndex - 1]) {
                return negativeColor;
              } else {
                return positiveColor;
              }
            },
          ],
        },
        formatter: function (val) {
          return val !== null ? numberFormat(val) + ' km' : ''; // Hide label if value is null
        },
      },
      legend: {
        show: false
      },
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     type: 'vertical',
      //     shadeIntensity: 1,
      //     opacityFrom: 0.1,
      //     opacityTo: 1
      //   }
      // },
    };

    var chart = new ApexCharts(document.querySelector("#hs-curved-area-charts"), options);
    chart.render();

    // Button switch
    const buttons = document.querySelectorAll('.mileage-graph-switch');

    buttons.forEach(button => {
      button.addEventListener('click', function () {
        const isNatural = this.id === 'mileage-graph-switch-natural';
        chart.destroy();

        const newOptions = {
          ...options,
          xaxis: isNatural ? naturalAxisConfig : {
            type: 'category',
            categories: chartData.dates,
            labels: {
              rotateAlways: true,
            }
          }
        };

        chart = new ApexCharts(document.querySelector("#hs-curved-area-charts"), newOptions);
        chart.render();
      });
    });
  }
});