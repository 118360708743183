import * as Sentry from "@sentry/browser";

// Only initialize Sentry in production
if (window.location.hostname === 'autokuk.cz') {
  Sentry.init({
    dsn: "https://9fce45963b67366277d6f54d9efeb1eb@o4508354470084608.ingest.de.sentry.io/4508354494267472",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    // Only trace requests to your production domain
    tracePropagationTargets: [
      "https://autokuk.cz",
      /^https:\/\/autokuk\.cz\/api/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Add environment tag
    environment: 'production',

    // Optional: Add release version if you want to track different versions
    // release: "your-release-version"
  });

  // Optional: Add Livewire specific tracking
  document.addEventListener('livewire:error', (event) => {
    Sentry.captureException(new Error(`Livewire Error: ${event.detail}`));
  });
}
